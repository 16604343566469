.mobile-frontpage {
  display: flex;
  flex-direction: column;
  margin-top: 7%;
}
.mobile-facebook {
  display: flex;
  flex-direction: column;

  margin-top: 15%;
  z-index: 0;
  justify-content: center;
}
.mobile-facebook-bg {
  background-color: azure;
}
.mobile-instagram {
  display: flex;
  flex-direction: column;
  margin-top: 15%;
  z-index: 0;
  justify-content: center;
}
.mobile-mcstyle {
  position: fixed;
  display: flex;
  background: transparent;
  bottom: -200px;
  z-index: 2;
}
.mobile-some-buttongroup {
  text-align: center;
  justify-content: space-between;
  margin-top: 15%;
}
.mobile-some-button {
  padding-left: 7%;
  padding-right: 7%;
  background-color: black;
  font-size: 100%;
  color: cornsilk;
}
.mobile-info-text {
  color: aliceblue;
  color: azure;
  padding: 1%;
  text-align: center;
}