.frontpage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.facebook {
  display: flex;
  margin-top: 15%;
  z-index: 0;
  height: 100%;
  width: 100%;
}
.facebook-bg {
  background-color: whitesmoke;
}
.instagram {
  display: flex;
  margin-top: 15%;
  z-index: 0;
  height: 100%;
}
.mcstyle {
  position: fixed;
  display: flex;
  background: transparent;
  margin-top: 15%;
  z-index: 2;
}
.info-text {
  color: aliceblue;
  margin-top: 7%;
  color: azure;
  padding: 1%;
  text-align: center;
  backdrop-filter: blur(2px);
}
.visitor-counter {
  background-color: black;
  margin-top: 16%;
}
