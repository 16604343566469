.guestbook {
    backdrop-filter: blur(7px);
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-top: 4%;
    padding-bottom: 1%;
    margin-left: 7%;
    margin-right: 7%;
  }
  
  .nimimerkki {
    background-color: black;
    padding-top: 1%;
  }
  .tekstikenttä {
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 1%;
    margin-top: 1%;
  }
  
  h1 {
    color: blue;
  }

  
  .viesti {
    margin-top: 1%;
    background-color: black;
    border: solid silver 1px;
  }