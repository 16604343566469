.carousel {
  display: block;
  text-align: center;
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 3%;
  padding-bottom: 10%;
}

.slide_direction {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.left,
.right {
  background-color: whitesmoke;
  border-radius: 100%;
  position: absolute;

  margin-left: 9%;
  margin-right: 9%;
  margin-top: -1%;
  height: 50px;
  width: 50px;
}
.left {
  left: 0;
  padding-top: 15px;
  padding-left: 3px;
}
.right {
  right: 0;
  padding-top: 15px;
  padding-left: 5px;
}
.carousel-indicator {
  margin-top: 20%;
  display: flex;
  justify-content: center;
}
.dot {
  background-color: #333;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-flex;
}
.active {
  background-color: whitesmoke;
}
.image-resize {
  max-width: 800px;
  max-height: 600px;
}
.p-carousel {
  background-color: black;
  margin-left: 25%;
  margin-right: 25%;
  padding-bottom: 2%;
  padding-top: 2%;
  padding-left: 2%;
  padding-right: 2%;
}
