.history {
  text-align: center;
  backdrop-filter: blur(7px);
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 10%;
  padding-bottom: 7%;
}
.h2-history {
    background-color: black;
    padding-top: 1%;
    padding-bottom: 1%;
  }
.p-history {
    background-color: black;
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
  }
  