.mobile-navbar {
  position: fixed;
  justify-content: space-evenly;
  top: 0px;
  width: 100%;
  background: black;
  padding: 1%;
  padding-top: 2%;
  margin-left: -2%;
  display: flex;
  flex-direction: row;
  gap: 7%;
  text-align: center;
  z-index: 1;
}
.mobile-navbutton {
  padding-left: 7%;
  padding-right: 7%;
  background-color: black;
  font-size: 150%;
  color: cornsilk;
}
