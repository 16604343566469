.member {
  display: flex;
  flex-direction: row;
  margin-bottom: 7%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 2%;
  
  text-align: center;
}

