.mobile-words {
  backdrop-filter: blur(7px);
  margin-top: 7%;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: 9%;
  padding-bottom: 9%;
  padding-left: 2%;
  padding-right: 2%;
  margin-left: 7%;
  margin-right: 7%;
}
