.mobile-holiday-greeting-christmas {
    position: fixed;
    display: flex;
    text-align: center;
    color: red;
    margin-top: 100%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .mobile-holiday-greeting-newyear {
    position: fixed;
    display: flex;
    text-align: center;
    color: silver;
    margin-top: 100%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .mobile-holiday-greeting-jögeva {
    position: fixed;
    display: flex;
    text-align: center;
    color: firebrick;
    margin-top: 100%;
    margin-left: 30%;
    margin-right: 2%;
  }
  .mobile-holiday-greeting-independence {
    position: fixed;
    display: flex;
    text-align: center;
    color: white;
    margin-top: 95%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .mobile-holiday-greeting-messu {
    position: fixed;
    display: flex;
    text-align: center;
    color: whitesmoke;
    margin-top: 100%;
    margin-left: 30%;
    margin-right: 2%;
  }