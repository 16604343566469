.navbar {
  position: fixed;
justify-content: center;
  top: 0px;
  width: 100%;
  background: black;
  padding: 1%;
  display: flex;
  flex-direction: row;
  gap: 1%;
  text-align: center;
  z-index: 1;
}
.navbutton {
  background-color: black;
  font-size: 150%;
  color: cornsilk;
}
.navbutton:hover {
  background-color: gainsboro;
  color: black;
}
.active {
  transition: transform 0.2s;
  box-shadow: 0 2px 30px rgb(255, 255, 255);
}
