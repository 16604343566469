.holiday-greeting-christmas {
    backdrop-filter: blur(2px);
    position: fixed;
    display: flex;
    text-align: center;
    color: red;
    margin-top: 10%;
    margin-left: 37%;
    margin-right: 35%;
  }
  .holiday-greeting-newyear {
    backdrop-filter: blur(2px);
    position: fixed;
    display: flex;
    text-align: center;
    color: silver;
    margin-top: 10%;
    margin-left: 35%;
    margin-right: 35%;
  }
  .holiday-greeting-indepedence {
    backdrop-filter: blur(2px);
    position: fixed;
    display: flex;
    text-align: center;
    color: white;
    margin-top: 10%;
    margin-left: 35%;
    margin-right: 35%;
  }
  .holiday-greeting-jögeva {
    backdrop-filter: blur(2px);
    position: fixed;
    display: flex;
    text-align: center;
    color: firebrick;
    margin-top: 10%;
    margin-left: 45%;
    margin-right: 35%;
  }
  .holiday-greeting-messu {
    backdrop-filter: blur(2px);
    position: fixed;
    display: flex;
    text-align: center;
    margin-top: 10%;
    margin-left: 45%;
    margin-right: 35%;
  }