.words {
  backdrop-filter: blur(7px);

  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: 9%;
  padding-bottom: 9%;
  padding-left: 2%;
  padding-right: 2%;

  margin-left: 30%;
  margin-right: 30%;
}
