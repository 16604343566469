.intro {
  text-align: center;
  backdrop-filter: blur(7px);

  padding-left: 7%;
  padding-right: 7%;
  padding-top: 10%;
  padding-bottom: 10%;
}
.h2-intro {
  background-color: black;
  padding-top: 1%;
  padding-bottom: 1%;
}
.p-intro {
  background-color: black;
  padding-bottom: 2%;
  padding-top: 2%;
  padding-left: 2%;
  padding-right: 2%;
}
