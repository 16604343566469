.mobile-carousel {
  display: block;
  text-align: center;
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 3%;
  padding-bottom: 10%;
}

.mobile-slide_direction {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.mobile-left,
.mobile-right {
  background-color: whitesmoke;
  border-radius: 100%;
  position: absolute;

  margin-left: -2%;
  margin-right: -2%;
  margin-top: -30%;
  height: 50px;
  width: 50px;
}
.mobile-left {
  left: 0;
  padding-top: 15px;
  padding-left: 3px;
}
.mobile-right {
  right: 0;
  padding-top: 15px;
  padding-left: 5px;
}
.mobile-carousel-indicator {
  margin-top: 20%;
  display: flex;
  justify-content: center;
}
.mobile-dot {
  background-color: #333;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-flex;
}
.active {
  background-color: whitesmoke;
}
.mobile-image-resize {
  max-width: 300px;
  max-height: 200px;
}

.mobile-p-carousel {
  background-color: black;
  margin-left: 7%;
  margin-right: 7%;
  padding-bottom: 2%;
  padding-top: 2%;
  padding-left: 2%;
  padding-right: 2%;
}
