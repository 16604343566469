
  body {
    background-image: url(/src/MCC\ BG\ 2\,655.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: 80%;
    background-color: black;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  h2 {
    color: azure;
  }

  h3 {
    color: bisque;
  }

  p {
    color: antiquewhite;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

