.mobile-members {
  backdrop-filter: blur(7px);

  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: 7%;
  margin-top: 7%;

  padding-bottom: 2%;
  padding-left: 2%;
  padding-right: 2%;
}

.mobile-members-bg {
  background-color: black;
}
