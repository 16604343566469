.visitor-data {
  font-size: small;
  color: antiquewhite;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-top: 1%;
  margin-left: 7%;
  margin-right: 7%;
  gap: 4%;
}
.all-visitor-data {
  font-size: small;
  color: antiquewhite;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-top: 1%;
  margin-left: 20%;
  margin-right: 20%;
  gap: 3%;
}

.visitor-header {
  color: antiquewhite;
  text-align: center;
  padding-top: 1%;
}

.visitor-footer {
  color: antiquewhite;
  font-size: small;
  text-align: center;
  margin-top: 1%;
}

.show-all {
  text-align: center;
}

.show-all-button {
  font-size: x-small;
  background-color: black;
  color: rgb(168, 195, 196);
}
